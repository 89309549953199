<template>
  <v-app>
    <dashboard-core-drawer style="max-width: 240px" />

    <dashboard-core-view />
    <!-- <dashboard-core-settings /> -->
  </v-app>
</template>

<script>
import db from "../../firebaseInit";
import FirebaseDB from "../utils/firebaseDB";
import NodesName from "../utils/nodesName";
import NodesFieldName from "../utils/nodesFieldName";
import firebase from "firebase";
export default {
  name: "DashboardIndex",
  data() {
    return {
      expandOnHover: false,
      userId: null,
      candidatures: null,
      NbrNotifications: null,
      NbrContacts: null,
      NbrRecruitments: null,
    };
  },
  components: {
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    DashboardCoreSettings: () =>
      import("../materialDashboard/components/core/Settings"),
    DashboardCoreView: () => import("./components/core/View"),
  },

  data: () => ({}),
  methods: {
    async NotificationNumber() {
      this.NbrContacts = await FirebaseDB.getCount(
        db.collection(NodesName.CONTACTS).where("read", "==", false)
      );
      localStorage.setItem("NbrContacts", this.NbrContacts);

      this.NbrRecruitments = await FirebaseDB.getCount(
        db.collection(NodesName.RECRUITMENTS).where("read", "==", false)
      );
      localStorage.setItem("NbrRecruitments", this.NbrRecruitments);
    },
  },
  beforeMount() {
    firebase.auth().onAuthStateChanged(function (user) {
      db.collection(NodesName.USERS_DETAILS)
        .where(NodesFieldName.EMAIL, "==", user.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            localStorage.setItem("adminID", doc.data().userId);
          });
        });
    });
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true;
      this.currentUserDisplayName = firebase.auth().currentUser.displayName;
      this.currentUserEmail = firebase.auth().currentUser.email;

      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((tokenResult) => {
          if (tokenResult.claims.admin) {
            this.NotificationNumber();
          }
        });
    }
  },
};
</script>
